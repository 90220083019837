/* profile__avatar */
.avatar__Img {
  @apply relative;
}
.avatar__Img .online__status {
  @apply absolute bottom-0 right-[3px] w-[16px] h-[16px] rounded-full bg-textSuccess border-[2px] border-bgWhiteSD z-[3];
}
.avatar__Img > .inner__wrapper {
  @apply w-full h-full rounded-[5px] overflow-hidden;
}
.img__element {
  @apply w-full pt-[100%] relative overflow-hidden;
}
.img__element img {
  @apply absolute top-0 left-0 object-cover object-center w-full h-full block;
}
.noImg__icon {
  @apply w-full h-full relative p-[24%] overflow-hidden bg-[#e3e8ed];
}
.noImg__icon svg {
  @apply w-full h-full text-[#818d97];
}
.img__loader__wrapper {
  @apply w-full pt-[100%] relative overflow-hidden before:content-[''] before:absolute before:top-0 before:left-0 before:w-full before:h-full before:bg-[#e3e8ed];
}
.img__loader__wrapper .inner__wrapper {
  @apply absolute top-0 left-0 w-full h-full flex items-center justify-center z-[2];
}
.img__loader__wrapper .small__loader__sd {
  @apply border-[#ffffff] border-r-primaryColor;
}
.noName__letter {
  @apply flex items-center justify-center w-full pt-[100%] relative overflow-hidden before:content-[""] before:absolute before:top-0 before:left-0 before:w-full before:h-full before:bg-[#E3E8ED];
}
.noName__letter .noName__letter__text {
  @apply absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] z-[2] text-[13px] font-bold text-primaryColor uppercase pt-[4px];
}
/* profile__avatar__end */
