/* tippy__dropdown */
[id^="tippy-"] {
  @apply !pointer-events-auto;
}
.tippy-box.tippy__dropdown {
  @apply shadow-raiseShadow py-[9px] bg-bgWhiteSD rounded-[6px] min-w-[94px];
}
.tippy-box.tippy__dropdown .tippy-content {
  @apply p-0 max-w-[250px]; /* min-w-[94px] */
}
.tippy-box.tippy__dropdown .tippy__dropdown__ul {
  @apply px-[12px] max-h-[270px] overflow-y-auto;
}
.tippy-box.tippy__dropdown .tippy__dropdown__ul .item__link {
  @apply flex items-center w-full text-[14px] font-medium text-textDark py-[7px] cursor-pointer duration-300 hover:!text-black hover:!no-underline;
}
.tippy-box.tippy__dropdown .tippy__dropdown__ul .item__link .item__text {
  @apply w-full whitespace-pre overflow-hidden text-ellipsis;
}
.tippy-box.tippy__dropdown .tippy__dropdown__ul .item__link .i__Icon {
  @apply w-[20px] h-[20px] p-[1px] mr-[8px] shrink-0 relative top-[-2px];
}
.tippy-box.tippy__dropdown .tippy__dropdown__ul .item__link .i__Icon svg {
  @apply fill-textSecondary duration-300;
}
.tippy-box.tippy__dropdown .tippy__dropdown__ul .item__link:hover .i__Icon svg {
  @apply fill-primaryColor;
}
.tippy-box.tippy__dropdown .tippy__dropdown__ul .item:first-child .item__link {
  @apply pt-0;
}
.tippy-box.tippy__dropdown .tippy__dropdown__ul .item:last-child .item__link {
  @apply pb-0;
}
.tippy-box.tippy__dropdown .tippy-arrow {
  @apply hidden;
}
/* tippy__dropdown__end */
