/* checkbox */
/* normal-checkbox */
.custom__checkbox__SD {
  @apply relative inline-flex items-center;
}
.custom__checkbox__SD input {
  @apply absolute top-0 left-0 w-full h-full opacity-0 z-[2] cursor-pointer;
}
.custom__checkbox__SD .label__SD {
  @apply text-[14px] font-medium text-textSecondary relative pl-[26px] mb-0 before:content-[''] before:absolute before:left-[3px] before:top-[2px] before:w-[14px] before:h-[14px] before:rounded-[2px] before:outline before:outline-2 before:outline-gray-500 after:content-[''] after:absolute after:left-[6px] after:top-[6px] after:h-[5px] after:w-[9px] after:border-l-[2px] after:border-b-[2px] after:border-l-[#ffffff] after:border-b-[#ffffff] after:-rotate-45 after:opacity-0;
}
.custom__checkbox__SD input:checked + .label__SD {
  @apply before:outline-none before:top-[1px] before:left-[2px] before:w-[17px] before:h-[17px] before:rounded-[4px] before:bg-black after:opacity-100;
}
/* normal-checkbox-end */

/* medium-checkbox */
.custom__checkbox__SD.custom__checkbox__SD__medium .label__SD {
  @apply text-[16px] pl-[28px] before:w-[16px] before:h-[16px] after:w-[10px] after:h-[5px] after:top-[8px];
}
.custom__checkbox__SD.custom__checkbox__SD__medium input:checked + .label__SD {
  @apply before:w-[19px] before:h-[19px] after:top-[7px];
}
/* medium-checkbox-end */

/* large-checkbox */
.custom__checkbox__SD.custom__checkbox__SD__large .label__SD {
  @apply text-[18px] pl-[32px] before:w-[18px] before:h-[18px] after:w-[10px] after:h-[5px] after:top-[8px] after:left-[7px];
}
.custom__checkbox__SD.custom__checkbox__SD__large input:checked + .label__SD {
  @apply before:w-[21px] before:h-[21px] after:top-[8px];
}
/* large-checkbox-end */

/* normal-checkbox-without-label */
.custom__checkbox__SD.without__label {
  @apply text-[0px] w-[16px] h-[16px];
}
.custom__checkbox__SD.without__label .label__SD {
  @apply text-[0px] w-full h-full pl-0 before:top-[1px] before:left-[1px] after:top-[4px] after:left-[4px];
}
.custom__checkbox__SD.without__label input:checked + .label__SD {
  @apply before:top-[-1px] before:left-[-1px] before:w-[18px] before:h-[18px] after:top-[4px] after:left-[3px];
}
/* normal-checkbox-without-label-end */

/* medium-checkbox-without-label */
.custom__checkbox__SD.custom__checkbox__SD__medium.without__label .label__SD {
  @apply text-[0px] w-full h-full pl-0 before:top-[0px] before:left-[0px] after:top-[4px] after:left-[2px];
}
.custom__checkbox__SD.custom__checkbox__SD__medium.without__label
  input:checked
  + .label__SD {
  @apply before:top-[-2px] before:left-[-2px] after:top-[4px] after:left-[2px];
}
/* medium-checkbox-without-label-end */

/* large-checkbox-without-label */
.custom__checkbox__SD.custom__checkbox__SD__large.without__label .label__SD {
  @apply text-[0px] w-full h-full pl-0 before:top-[0px] before:left-[0px] after:top-[5px] after:left-[3px];
}
.custom__checkbox__SD.custom__checkbox__SD__large.without__label
  input:checked
  + .label__SD {
  @apply before:top-[-2px] before:left-[-2px] after:top-[5px] after:left-[3px];
}
/* large-checkbox-without-label-end */

/* normal-checkbox-with-left-label */
.custom__checkbox__SD.label__left .label__SD {
  @apply pl-0 pr-[27px] before:left-unset before:right-[3px] after:left-unset after:right-[6px];
}
.custom__checkbox__SD.label__left input:checked + .label__SD {
  @apply before:left-unset before:right-[2px];
}
/* normal-checkbox-with-left-label-end */

/* medium-checkbox-with-left-label */
.custom__checkbox__SD.custom__checkbox__SD__medium.label__left .label__SD {
  @apply pl-0 pr-[30px] before:left-unset before:right-[3px] after:left-unset after:right-[7px];
}
.custom__checkbox__SD.custom__checkbox__SD__medium.label__left
  input:checked
  + .label__SD {
  @apply before:left-unset before:right-[2px];
}
/* medium-checkbox-with-left-label-end */

/* large-checkbox-with-left-label */
.custom__checkbox__SD.custom__checkbox__SD__large.label__left .label__SD {
  @apply pl-0 pr-[32px] before:left-unset before:right-[3px] after:left-unset after:right-[8px];
}
.custom__checkbox__SD.custom__checkbox__SD__large.label__left
  input:checked
  + .label__SD {
  @apply before:left-unset before:right-[2px];
}
/* large-checkbox-with-left-label-end */
/* checkbox-end */
