/* breadcrumbs css start */
.breadcrumbs {
  @apply flex flex-wrap items-center p-3 bg-[#6558f50f];
}
/* .breadcrumbs__wrapper{
  @apply mt-5;
} */
.breadcrumbs .breadcrumbLink {
  @apply inline-block text-[14px] font-Poppins text-[#454849] pr-[15px] mr-[15px] relative hover:underline hover:text-primaryColor before:content-[''] before:absolute before:w-[7px] before:h-[7px] before:border-l-[1px] before:border-l-[#454849] before:border-b-[1px] before:border-b-[#454849] before:rotate-[-135deg] before:right-0 before:top-[50%] before:translate-y-[-50%] ;
}
.breadcrumbs span.breadcrumbLink {
  @apply hover:no-underline;
}
.breadcrumbs .breadcrumbLink:last-child {
  @apply mr-0 pr-0 before:hidden;
}
.breadcrumbs .breadcrumbLink.active {
  @apply text-[#6558F5] font-semibold;
}
.breadcrumbs > .breadcrumbLink:last-child {
  @apply mr-[15px];
}
/* breadcrumbs css end */
