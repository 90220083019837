/* action__modal__wrapper__SD */
.action__modal__wrapper__SD {
  @apply fixed h-screen top-0 left-0 z-[12] w-screen items-center justify-center flex;
}
.action__modal__wrapper__SD .modal__close__btn {
  @apply absolute top-[12px] right-[12px] w-[34px] h-[34px] rounded-full text-[0px] shadow-raiseShadow duration-300 before:content-[''] before:absolute before:top-[50%] before:left-[50%] before:translate-y-[-50%] before:translate-x-[-50%] before:w-[16px] before:h-[3px] before:bg-textDark before:rotate-45 before:duration-300 after:content-[''] after:absolute after:top-[50%] after:left-[50%] after:translate-y-[-50%] after:translate-x-[-50%] after:w-[16px] after:h-[3px] after:bg-textDark after:-rotate-45 after:duration-300 hover:bg-primaryColor hover:before:bg-[#ffffff] hover:after:bg-[#ffffff];
}
.action__modal__wrapper__SD .action__modal__overlay__SD {
  @apply h-screen pointer-events-none fixed top-0 left-0 w-screen bg-[#2e32341a] backdrop-blur-[8px];
}
.action__modal__wrapper__SD .action__modal__content__wrapper {
  @apply relative z-[2] max-w-[calc(100%_-_30px)] mx-auto rounded-[30px] bg-bgWhiteSD p-[40px];
}
.action__modal__wrapper__SD .action__btn__wrapper {
  @apply flex justify-center items-center mt-[20px];
}
.action__modal__wrapper__SD .action__btn__wrapper .button__SD {
  @apply mr-[14px] last:mr-0;
}
/* action__modal__wrapper__SD__end */
