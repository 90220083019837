/* sidebar */
.sidebar__wrapper {
  @apply min-h-screen w-[270px] sm:w-full bg-bgWhiteSD fixed border-r-[1px] border-[#C3CFD9];
}

.sidebar__wrapper .collapse__btn svg {
  @apply w-full h-full text-textWhiteSD;
}

.sidebar__wrapper .submenu__wrapper .s__Menu__Item__Link.active {
  @apply border-l-[2px] border-[#6558F5];
}

.submenu_item .s__Menu__Text {
  @apply !text-sm;
}

.sidebar__wrapper .s__Menu__Item.active>.s__Menu__Item__Link .s__Menu__Icon,
.sidebar__wrapper .s__Menu__Item .s__Menu__Item__Link:hover .s__Menu__Icon,
.sidebar__wrapper .submenu__wrapper .s__Menu__Item__Link.active .s__Menu__Icon {
  @apply bg-bgWhiteSD;
}

.sidebar__wrapper .s__Menu__Item.active>.s__Menu__Item__Link .s__Menu__Icon svg,
.sidebar__wrapper .s__Menu__Item .s__Menu__Item__Link:hover .s__Menu__Icon svg,
.sidebar__wrapper .submenu__wrapper .s__Menu__Item__Link.active .s__Menu__Icon svg,
.sidebar__wrapper .submenu__wrapper .s__Menu__Item__Link.active .s__Menu__Icon svg line,
.sidebar__wrapper .s__Menu__Item.active>.s__Menu__Item__Link .s__Menu__Icon svg line,
.sidebar__wrapper .s__Menu__Item .s__Menu__Item__Link:hover .s__Menu__Icon svg line,
.sidebar__wrapper .submenu__wrapper .s__Menu__Item__Link.active .s__Menu__Icon svg path,
.sidebar__wrapper .s__Menu__Item.active>.s__Menu__Item__Link .s__Menu__Icon svg path,
.sidebar__wrapper .s__Menu__Item .s__Menu__Item__Link:hover .s__Menu__Icon svg path,
.sidebar__wrapper .submenu__wrapper .s__Menu__Item__Link.active .s__Menu__Icon svg rect,
.sidebar__wrapper .s__Menu__Item.active>.s__Menu__Item__Link .s__Menu__Icon svg rect,
.sidebar__wrapper .s__Menu__Item .s__Menu__Item__Link:hover .s__Menu__Icon svg rect {
  @apply fill-[#6558F5];
}

.sidebar__wrapper .s__Menu__Item.active .s__Menu__Item__Link .s__Menu__Text,
.sidebar__wrapper .s__Menu__Item .s__Menu__Item__Link:hover .s__Menu__Text {
  @apply text-[#6558F5];
}

.sidebar__wrapper .s__Menu__Item .s__Menu__Item__Link .s__Menu__Text {
  @apply w-full whitespace-pre overflow-hidden text-ellipsis text-[16px] leading-[20px];
}

.sidebar__wrapper .s__Menu__Item.active>.s__Menu__Item__Link .s__Menu__Text,
.sidebar__wrapper .submenu__wrapper .s__Menu__Item__Link.active .s__Menu__Text {
  @apply text-[#6558F5];
}

.sidebar__wrapper .s__Menu__Item .logout {
  @apply bg-red-500 mb-0 mt-0;
}

.sidebar__wrapper .s__Menu__Item.logout__btn .s__Menu__Icon svg {
  @apply text-[#ffffff];
}

.sidebar__wrapper .s__Menu__Item.logout__btn .s__Menu__Item__Link:hover .s__Menu__Icon {
  @apply bg-transparent;
}

.sidebar__collapse .sidebar__wrapper {
  @apply w-[90px];
}

.collapse__btn {
  @apply sm:right-0
}

.sidebar__collapse .collapse__btn {
  @apply sm:right-[-14px]
}

.sidebar__collapse .sidebar__wrapper .s__Menu__Item .s__Menu__Item__Link .s__Menu__Text {
  @apply hidden;
}

.sidebar__collapse .sidebar__wrapper .s__Menu__Item .s__Menu__Item__Link {
  @apply block px-4 py-[10px];
}

.sidebar__collapse .sidebar__wrapper .s__Menu__Arrow svg {
  @apply w-5 h-5;
}

.sidebar__collapse .sidebar__wrapper .s__Menu__Item.logout__btn .s__Menu__Item__Link {
  @apply w-full !p-0;
}

.sidebar__collapse .s__Menu__Item .s__Menu__Item__Link .s__Menu__Arrow {
  @apply hidden;
}

.sidebar__collapse .sidebar__wrapper .submenu__wrapper .s__Menu__Item__Link {
  @apply p-0;
}

.sidebar__collapse .sidebar__wrapper .submenu__wrapper {
  @apply pl-0;
}


.sidebar__collapse .side-menu .side-menu-icon {
  @apply flex items-center justify-center w-[20px] h-[20px]
}

.sidebar__collapse .side-menu .side-menu-icon svg,
.sidebar__collapse .s__Menu__Icon__Main,
.sidebar__collapse .s__Menu__Icon__Main svg,
.s__Menu__Icon__Main svg {
  @apply w-[20px] h-[20px]
}

.collapse-logo,
.sidebar__collapse .uncollapse-logo,
.sidebar__collapse .s__Menu__Text {
  @apply hidden;
}

.sidebar__collapse .sidebar-bottom .s__Menu__Item {
  @apply p-4;
}

.sidebar__collapse .collapse-logo {
  @apply block w-[30px] m-auto;
}

.sidebar__collapse .menu__wrapper.super-logout {
  @apply h-[calc(100vh-280px)];
}

.sidenav-menu::-webkit-scrollbar {
  @apply hidden;
}

.s__Menu__Icon>div {
  @apply w-full h-full;
}

.s__Menu__Item__Link .s__Menu__Icon svg {
  @apply w-full h-full
}

/* .sidebar__collapse .s__Menu__Item__Link .s__Menu__Icon svg {
  @apply h-[15px] w-[15px];
} */

.s__Menu__Item:hover .side-menu-icon svg path,
.s__Menu__Item:hover .s__Menu__Icon__Main svg path,
.s__Menu__Item.active .side-menu-icon svg path,
.s__Menu__Item.active .s__Menu__Icon__Main svg path {
  @apply fill-[#6558F5];
}

.s__Menu__Item:hover .icon-text,
.s__Menu__Item.active .icon-text {
  @apply text-[#6558F5];
}

.s__Menu__Item:hover .icon-text svg polyline {
  @apply stroke-[#000];
}

.s__Menu__Item__Link:hover .s__Menu__Text {
  @apply !text-[#6558F5]
}

.s__Menu__Item.active {
  @apply relative before:content-[''] before:absolute before:left-3 before:w-[2px] before:h-[20px] before:bg-[#4F46E5] before:top-[10px];
}

.sidebar__collapse .header__logo {
  @apply justify-center mb-[30px];
}

.sidebar__collapse .new-note-btn {
  @apply bg-white;
}

.sidebar__collapse .new-note-btn .relative {
  @apply w-fit mx-auto;
}

.sidebar__collapse .new-note-btn .inline-flex {
  @apply hidden;
}

.sidebar__collapse .s__Menu__Item .s__Menu__Icon {
  @apply mx-auto;
}

/* .sidebar__collapse .s__Menu__Item .s__Menu__Icon svg{
  @apply w-[13px];
} */

.sidebar__collapse .s__Menu__Item .max-w-full {
  @apply justify-center;
}

.sidebar__collapse .s__Menu__Item__Link .s__Menu__Icon__Main {
  @apply mx-auto;
}

.sidebar__collapse .new-note-btn .btn-text {
  @apply rounded-lg cursor-pointer px-2;
}

.sidebar__collapse .new-note-btn .btn-text p {
  @apply hidden;
}

.sidebar__collapse .s__Menu__Item {
  @apply before:top-[8px] before:left-[5px];
}

.sidebar__collapse .logout__btn .s__Menu__Item__Link svg {
  @apply mx-auto;
}

.sidebar__collapse .s__Menu__Item {
  @apply min-h-[auto];
}


.sidebar__collapse .s__Menu__Item:has(.view-notes) {
  @apply pl-4;
}

.sidebar__collapse .icon-text {
  @apply hidden;
}


.flow-root::-webkit-scrollbar {
  @apply hidden;
}


.content-table-data .rdt_Table .rdt_TableBody .rdt_TableRow {
  @apply relative min-h-[auto];
}

.content-table-data .rdt_Table .rdt_TableBody .rdt_TableRow .rdt_TableCell:first-child {
  @apply absolute top-1/2 -translate-y-1/2 opacity-0 pl-[6px];
}

.content-table-data .rdt_Table .rdt_TableBody .rdt_TableRow .rdt_TableCell:nth-child(2) {
  @apply !w-[80%] max-w-full ml-2 px-0;
}

.content-table-data .rdt_Table .rdt_TableBody .rdt_TableRow .rdt_TableCell:nth-child(3) {
  @apply invisible w-0 max-w-[50px] min-w-0 duration-0;
}

.content-table-data .rdt_Table .rdt_TableBody .rdt_TableRow:hover {
  @apply bg-[#6558f50f];
}

.content-table-data .rdt_Table .rdt_TableBody .rdt_TableRow:hover .rdt_TableCell:nth-child(3) {
  @apply visible !w-[14px];
}

.content-table-data .rdt_Table .rdt_TableBody .rdt_TableRow:hover .rdt_TableCell:first-child {
  @apply opacity-100 duration-200;
}

.content-table-data .rdt_Table .rdt_TableBody .rdt_TableRow:hover .rdt_TableCell:nth-child(2) {
  @apply ml-10 duration-200;
}

.content-table-data .data-check .rdt_Table .rdt_TableBody .rdt_TableRow .rdt_TableCell:first-child {
  @apply opacity-100;
}

.content-table-data .data-check .rdt_Table .rdt_TableBody .rdt_TableRow .rdt_TableCell:nth-child(2) {
  @apply ml-10;
}

.meetinglist .rdt_Table .rdt_TableBody .rdt_TableRow:hover,
.patientlist .rdt_Table .rdt_TableBody .rdt_TableRow:hover {
  @apply bg-[#6558f50f];
}